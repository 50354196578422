/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

export const USAGE_DEFINING = 'Defining';
export const USAGE_DESCRIPTIVE = 'Descriptive';
export const USAGE_OFFER = 'Offer';
export const USAGE_DISPLAY = 'Display';
export const ATTR_PICKUP_IN_STORE = 'PickUpInStore';
export const STRING_TRUE = 'true';
export const RA_EXCLUSIVE = 'Exclusive';
export const PRODUCT_LIST_DEFAULT_PAGE_SIZE = 24;
export const PRODUCT_LIST_PAGE_SIZES = [50, 100];
export const SWATCH = 'swatch';
export const SIZE = 'Size';
export const RADIO = 'radio';
export const PPESIZE = 'PPESize';
export const SIZE_GUIDE_ESPOT = 'SizeGuideEspot';
export const SERVICE_UPDATE_ESPOT = 'Learn More ESpot';
export const LLP_SERVICE_UPDATE_ESPOT =
	'PDP_LLP_Subscription-MerchandisingAssociations-Widget-Message_ESpot';

type Option = {
	key: string;
	value: string;
	translationKey: 'relevance' | 'brands' | 'name' | 'priceLowToHigh' | 'priceHighToLow';
};

export const SORT_OPTIONS = {
	defaultSortOptions: [
		{
			key: 'SN_NO_SORT',
			value: '0',
			translationKey: 'relevance',
		},
		// {
		// 	key: 'SN_SORT_BY_BRANDS',
		// 	value: '1',
		// 	translationKey: 'brands',
		// },
		{
			key: 'SN_SORT_BY_NAME',
			value: '2',
			translationKey: 'name',
		},
	] as Option[],
	priceSortOptions: [
		{
			key: 'SN_SORT_LOW_TO_HIGH',
			value: '3',
			translationKey: 'priceLowToHigh',
		},
		{
			key: 'SN_SORT_HIGH_TO_LOW',
			value: '4',
			translationKey: 'priceHighToLow',
		},
	] as Option[],
};

export const FACET_Advantage_Savings = 'Advantage Savings';
export const FACET_Average_Rating = 'Average Rating';
export const Fast_Ship = 'Fast Ship';
export const PLP_Advantage_Savings = 'Advantage Savings';
export const PLP_Advantage_Promotion = 'Promotion';
